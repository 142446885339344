import "../styles/components/work-teaser.scss";

import React from "react";
import classNames from "classnames";

interface WorkTeaserProps {
  active?: boolean;
  data: {
    title: string;
    altText: string;
    postTemplateSwat: {
      image: {
        altText: string;
        sourceUrl: string;
      };
    };
  };
}

/**
 * @param props
 * @param props.data
 * @param props.active
 */
function WorkTeaser({ active = true, data }: WorkTeaserProps) {
  return (
    <div className={classNames("WorkTeaser", { "WorkTeaser--active": active })}>
      {data?.title && <h3 className="WorkTeaser-title">{data?.title}</h3>}
      <img
        alt={data?.postTemplateSwat?.image?.altText}
        className="WorkTeaser-static"
        src={data?.postTemplateSwat?.image?.sourceUrl}
      />
      <img
        alt={data?.postTemplateSwat?.image?.altText}
        className="WorkTeaser-animated"
        src={data?.postTemplateSwat?.image?.sourceUrl}
      />
    </div>
  );
}

export default WorkTeaser;
