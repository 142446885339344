import "../styles/components/work-feed.scss";

import React, { useEffect, useRef, useState } from "react";

import WorkTeaser from "./WorkTeaser";

interface WorkFeedProps {
  items: any[];
}

/**
 * @param props
 * @param props.items
 */
function WorkFeed({ items }: WorkFeedProps) {
  const [activeIndex, setActiveIndex] = useState<null | number>(null);

  const elementRef = useRef(null);

  /**
   * @param e
   */
  function handleScroll() {
    if (elementRef.current) {
      const progress =
        elementRef.current.scrollTop /
        (elementRef.current.scrollHeight - elementRef.current.offsetHeight);
      const newActiveIndex =
        ((items.length - 1) * Math.round(progress * (items.length - 1))) /
        (items.length - 1);
      setActiveIndex(newActiveIndex);
    }
  }

  /**
   * @param e
   */
  function handleWheel(e) {
    if (e.deltaY !== 0 && elementRef.current) {
      elementRef.current.scrollTop += e.deltaY;
    }
  }

  useEffect(() => {
    document.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <ul className="WorkFeed" onScroll={handleScroll} ref={elementRef}>
      {items.map((item, i) => (
        <li className="WorkFeed-item" key={i}>
          <WorkTeaser active={i === activeIndex} data={item} />
        </li>
      ))}
    </ul>
  );
}

export default WorkFeed;
