import "../../styles/pages/creative.scss";

import React, { useRef } from "react";

import IconScrollDown from "../../icons/scroll-down.svg";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import WarpedImage from "../../components/WarpedImage";
import WorkFeed from "../../components/WorkFeed";

/**
 *
 */
function Swat({ pageContext }) {
  const mainContentRef = useRef(null);
  const { seo } = pageContext;
  const { title, metaDesc } = seo;
  const creativeData = pageContext;
  /**
   *
   */
  function scrollDown() {
    mainContentRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const bodyData = creativeData?.pageTemplateSwat;
  const bodyDataCopyList = creativeData?.pageTemplateSwat?.bodyCopy || [];
  const swatBrands = creativeData?.allWpSwatBrand?.nodes || [];

  const sortedItems = swatBrands.sort(function (a, b) {
    return new Date(b?.date).valueOf() - new Date(a?.date).valueOf();
  });

  return (
    <Layout
      gradient={true}
      gradientMobileOnly={true}
      links={true}
      theme="light"
      variant="creative"
    >
      <SEO title={title} description={metaDesc} />

      <article className="Creative">
        <header className="Creative-header">
          {bodyData?.warpedImage && (
            <WarpedImage alt="" src={bodyData?.warpedImage?.sourceUrl} />
          )}
          <button className="Creative-scrollDown" onClick={() => scrollDown()}>
            <IconScrollDown />
          </button>
        </header>
        <div className="Creative-main" ref={mainContentRef}>
          <div className="Creative-mainInner">
            {bodyData?.heading && (
              <p className="Creative-mainContent">{bodyData?.heading}</p>
            )}
            <div className="Creative-clients">
              <div className="Creative-clientsLabel">
                {bodyData?.bodyHeading}
              </div>
              <ul className="Creative-clientsList">
                {bodyDataCopyList.map((list, i) => (
                  <li className="Creative-clientsItem" key={i}>
                    {list?.copyList}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="Creative-work">
            <WorkFeed items={sortedItems} />
          </div>
        </div>
      </article>
    </Layout>
  );
}

export default Swat;

// const creativeData = useStaticQuery(graphql`
// query {
//   wpPage(slug: { eq: "swat" }) {
//     pageTemplateSwat {
//       warpedImage {
//         sourceUrl
//       }
//       heading
//       bodyHeading
//       bodyCopy {
//         copyList
//       }
//     }
//   }
//   allWpSwatBrand {
//     nodes {
//       date
//       title
//       postTemplateSwat {
//         image {
//           altText
//           sourceUrl
//         }
//       }
//     }
//   }
// }
// `);
